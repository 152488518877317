.laColeccinOficial {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  font-size: var(--title-desktop);
  line-height: var(--line-height-desktop);
}
.losFanticosDel {
  display: block;
}
.losFanticosDelContainer {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--desk-p2-size);
  line-height: var(--line-height-desktop);
}
.text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-lg);
  max-width: 1200px;
  margin: 0 auto;
}
.sectionabout {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xl) 128px;
  align-items: center;
  justify-content:center;
  background-image: url(../../public/section-about-background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: left;
  font-size: var(--desk-h31-size);
  color: var(--white-bg1);
  font-family: var(--desk-h31);
}

@media screen and (max-width: 960px) {
  .text{
    max-width: 100%;
  }
  .sectionabout {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media screen and (max-width: 630px) {
  .laColeccinOficial {
    font-size: var(--title-desktop);
  }
  .losFanticosDelContainer {
    font-size: var(--p1-size);
  }
  .text {
    max-width: 900px;
    gap: var(--gap-2xs);
  }
  .sectionabout {
      padding-left: 24px;
      padding-right: 24px;
    box-sizing: border-box;
  }
}
