/* @import url("https://fonts.googleapis.com/css2?family=Dinamit:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Font+Awesome+6+Free:wght@400&display=swap"); */
@font-face { 
  font-family: "Dinamit";   /*Can be any text*/ 
  src: local("Dinamit"), 
    url("assets/fonts/Dinamit/Dinamit.otf") format("otf");
}
@font-face { 
  font-family: "Dinamit-Bold";   /*Can be any text*/ 
  src: local("Dinamit-Bold"), 
    url("assets/fonts/Dinamit/Dinamit-Bold.otf") format("otf");
}
@font-face { 
  font-family: "Dinamit-Bold-Italic";   /*Can be any text*/ 
  src: local("Dinamit-Bold-Italic"), 
    url("assets/fonts/Dinamit/Dinamit-Bold-Italic.otf") format("otf");
}
@font-face { 
  font-family: "Dinamit-ExtraLight-Italic";   /*Can be any text*/ 
  src: local("Dinamit-ExtraLight-Italic"), 
    url("assets/fonts/Dinamit/Dinamit-ExtraLight-Italic.otf") format("otf");
}
@font-face { 
  font-family: "Dinamit-ExtraLight";   /*Can be any text*/ 
  src: local("Dinamit-ExtraLight"), 
    url("assets/fonts/Dinamit/Dinamit-ExtraLight.otf") format("otf");
}
@font-face { 
  font-family: "Dinamit-Light-Italic";   /*Can be any text*/ 
  src: local("Dinamit-Light-Italic"), 
    url("assets/fonts/Dinamit/Dinamit-Light-Italic.otf") format("otf");
}
@font-face { 
  font-family: "Dinamit-Light";   /*Can be any text*/ 
  src: local("Dinamit-Light"), 
    url("assets/fonts/Dinamit/Dinamit-Light.otf") format("otf");
}
@font-face { 
  font-family: "Dinamit-Medium-Italic";   /*Can be any text*/ 
  src: local("Dinamit-Medium-Italic"), 
    url("assets/fonts/Dinamit/Dinamit-Medium-Italic.otf") format("otf");
}
@font-face { 
  font-family: "Dinamit-Medium";   /*Can be any text*/ 
  src: local("Dinamit-Medium"), 
    url("assets/fonts/Dinamit/Dinamit-Medium.otf") format("otf");
}
@font-face { 
  font-family: "Dinamit-Regular-Italic";   /*Can be any text*/ 
  src: local("Dinamit-Regular-Italic"), 
    url("assets/fonts/Dinamit/Dinamit-Regular-Italic.otf") format("otf");
}
@font-face { 
  font-family: "Dinamit-Regular";   /*Can be any text*/ 
  src: local("Dinamit-Regular"), 
    url("assets/fonts/Dinamit/Dinamit-Regular.otf") format("otf");
}
@font-face { 
  font-family: "Dinamit-SemiBold-Italic";   /*Can be any text*/ 
  src: local("Dinamit-SemiBold-Italic"), 
    url("assets/fonts/Dinamit/Dinamit-SemiBold-Italic.otf") format("otf");
}
@font-face { 
  font-family: "Dinamit-SemiBold";   /*Can be any text*/ 
  src: local("Dinamit-SemiBold"), 
    url("assets/fonts/Dinamit/Dinamit-SemiBold.otf") format("otf");
}
@font-face { 
  font-family: "Dinamit-Thin-Italic";   /*Can be any text*/ 
  src: local("Dinamit-Thin-Italic"), 
    url("assets/fonts/Dinamit/Dinamit-Thin-Italic.otf") format("otf");
}
@font-face { 
  font-family: "Dinamit-Thin";   /*Can be any text*/ 
  src: local("Dinamit-Thin"), 
    url("assets/fonts/Dinamit/Dinamit-Thin.otf") format("otf");
}

*{
  margin: 0;
  font-family: "Dinamit-Regular", sans-serif;
}

body {
  margin: 0;
  line-height: normal;
  
}
:root {
  /* fonts */
  
  --p1: Inter;
  --desk-h31: "Dinamit", sans-serif;
  --font-font-awesome-6-free: "Font Awesome 6 Free";

  /* font sizes */
  --micro-size: 6px;
  --small-size: 10px;
  --p1-size: 16px;
  --font-size-lg: 13.46px;
  --font-size-xl: 14px;
  --font-size-2xl: 15.38px;
  --desk-p2-size: 16px;
  --desk-h31-size: 20px;
  --font-size-5xl: 23.08px;
  --font-size-6xl: 40px;
  --font-size-xs: 8px;
  --small-size: 10px;
  --mobile-p3-size: 16px;
  --font-size-3xl: 15.38px;
  --mobile-h33-size: 16px;
  --desk-h32-size: 20px;
  --font-size-7xl: 40px;
  --title-desktop: 21px;
  --p-desktop: 16px;

  /** line height*/
  --line-height-desktop:1.5;


  /* Colors */
  --blue-bg3: #083a65;
  --blue-dark-bg1: #002752;
  --white-bg1: #f1f2f2;
  --persea-nine-white: #ebede9;
  --gray3: #79838e;
  --gray5: rgba(121, 131, 142, 0.5);
  --bbva-blanco2: #feffff;
  --persea-nine-gray-2: #819796;
  --blue-darker2: #011e41;
  --black5: #001429;
  --color-gray-500: rgba(0, 0, 0, 0.5);
  --color-gray-600: rgba(255, 255, 255, 0);
  --color-gray-700: rgba(21, 29, 40, 0.5);
  --black4: rgba(0, 20, 41, 0.5);
  --color-white: #fff;
  --persea-nine-green: #a7f070;
  --light-gray-bg1: #e6e7e8;
  --yellow-bg3: #f3e82d;
  --red-bg3: #ff1c39;
  --black2: rgba(0, 20, 41, 0.5);
  --persea-nine-white1: #ebede9;
  --color-lightseagreen: #02a5a5;
  --gray2: rgba(121, 131, 142, 0.5);
  --yellow-bg2: #f3e82d;

  /* Gaps */
  --gap-0: 0px;
  --gap-7xs: 4px;
  --gap-6xs: 4.7px;
  --gap-5xs: 5px;
  --gap-4xs: 6px;
  --gap-3xs: 7.69px;
  --gap-2xs: 8px;
  --gap-xs: 10px;
  --gap-sm: 12px;
  --gap-md: 15px;
  --gap-lg: 16px;
  --gap-xl: 19.23px;
  --gap-2xl: 20px;
  --gap-3xl: 24px;
  --gap-4xl: 32px;
  --gap-5xl: 40px;
  --gap-6xl: 48px;
  --gap-7xl: 50px;

  /* Paddings */
  --padding-9xs: 4px;
  --padding-8xs: 6px;
  --padding-7xs: 8px;
  --padding-6xs: 9.394495964050293px;
  --padding-5xs: 10px;
  --padding-4xs: 12px;
  --padding-3xs: 12.525994300842285px;
  --padding-2xs: 16px;
  --padding-xs: 19px;
  --padding-sm: 23px;
  --padding-md: 24px;
  --padding-lg: 26px;
  --padding-xl: 32px;
  --padding-2xl: 34px;
  --padding-3xl: 48px;
  --padding-4xl: 60px;
  --padding-5xl: 64px;
  --padding-6xl: 80px;
  --padding-7xl: 106px;
  --padding-8xl: 106.12799835205078px;
  --padding-9xl: 128px;

  /* border radiuses */
  --br-2xs: 2px;
  --br-xs: 3px;
  --br-sm: 3.93px;
  --br-md: 4px;
  --br-lg: 7.69px;
  --br-xl: 8px;
  --br-2xl: 38px;
  --br-md1: 2px

  /* border */
}
