.adquirirAhora {
  position: relative;
}
.boton {
  border-radius: var(--br-md);
  background-color: var(--white-bg1);
  box-shadow: 0 1px 1px rgba(0, 20, 41, 0.75);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-4xs) var(--padding-3xl);
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--desk-h31-size);
  color: var(--black5);
  font-family: var(--desk-h31);
  cursor: pointer;
}
