.frameParent,
.text1,
.textWrapper {
  display: flex;
  flex-direction: column;
}


.frameParent,
.textWrapper {
  align-self: stretch;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
}

.frameParent {
  border-radius: var(--br-md);
}

.preguntaNmero1Parent {
  width: 100%;
  align-self: stretch;
  border-radius: var(--br-2xs);
  background-color: var(--black5);
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; */
  display: grid;
  grid-template-columns: 1fr 20px;
  padding: var(--padding-7xs) 0;
  font-family: var(--desk-h31);
  font-style: normal;
  font-weight: 400;
  font-size: var(--p-desktop);
  line-height: 15px;
  color: #F1F2F2;
  cursor: pointer;
  line-height: var(--line-height-desktop);
}

.preguntaNmero1,
.vector {
  position: relative;
  flex-shrink: 0;
}
.preguntaNmero1 {
  display: inline-block;
  width: auto;
}

.vectorIconUp {
  position: relative;
  width: 12px;
  height: 6.5px;
  flex-shrink: 0;
  transform: rotate(180deg);
  cursor: pointer;
  user-select: none;
}

.vectorIconDown {
  position: relative;
  width: 12px;
  height: 6.5px;
  flex-shrink: 0;
  cursor: pointer;
  user-select: none;
}

.textWrapper {
  background-color: var(--blue-dark-bg1);
  height: 1px;
  flex-shrink: 0;
  padding: var(--padding-2xs) var(--padding-xs);
  box-sizing: border-box;
  opacity: 0;
  font-size: var(--desk-p2-size);
}


.text1 {
  width: 327px;
  align-items: center;
  justify-content: center;
  gap: var(--gap-2xs);
}

.loremIpsumDolor,
.text,
.titulo1 {
  align-self: stretch;
  width: 100%;
}


.titulo1 {
  position: relative;
  display: block;
}

.loremIpsumDolor {
  position: relative;
  font-size: var(--p-desktop);
  width: auto;
  height: auto;
  line-height: var(--line-height-desktop);
}

.loremIpsumDolor,p{
  align-self: stretch;
  width: 100%;
  line-height: var(--line-height-desktop);
}


.contentAnswer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 19px;
  height: auto;
  background: #002752;
  align-self: stretch;
}





