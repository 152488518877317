.imgIcon,
.imgIcon1 {
  position: absolute;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.imgIcon {
  height: 100%;
  width: 100%;
  top: 6.6px;
  right: 0;
  bottom: -6.6px;
}
.imgIcon1 {
  height: calc(100% - 130.6px);
  width: calc(100% - 261.6px);
  top: 0;
  right: 261.6px;
  bottom: 130.6px;
  display: none;
}
.imgParent {
  align-self: stretch;
  flex: 1;
  position: relative;
}
.ilustracin {
  align-self: stretch;
  height: 261.6px;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.elClsico1 {
  position: relative;
  font-size: var(--mobile-h33-size);
  font-family: var(--mobile-h33);
  color: var(--white-bg1);
  text-align: left;
}
.elClsico1,
.particula1De {
  align-self: stretch;
}
.enTuPosesin,
.particula1De {
  position: relative;
  font-size: var(--mobile-p3-size);
  font-family: var(--mobile-h33);
  color: var(--persea-nine-white1);
  text-align: left;
}
.info {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.metamask {
  position: relative;
  font-size: var(--mobile-p3-size);
  line-height: 100%;
  font-family: var(--p);
  color: var(--black5);
  text-align: center;
}
.boton,
.ttulo {
  flex-direction: row;
  align-items: center;
}
.boton {
  border-radius: var(--br-2xs);
  background-color: var(--white-bg1);
  box-shadow: 0 1px 1px rgba(0, 20, 41, 0.75);
  overflow: hidden;
  display: none;
  padding: var(--padding-7xs);
  justify-content: center;
}
.ttulo {
  background-color: var(--black5);
  display: flex;
  padding: var(--padding-4xs);
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.nft,
.ttulo,
.tuNftSe {
  align-self: stretch;
}
.nft {
  border-radius: var(--br-sm);
  height: 339px;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
.tuNftSe {
  margin: 0;
  position: relative;
  font-size: var(--mobile-p3-size);
  font-weight: 500;
  color: var(--black5);
  text-align: left;
}
.elClsicoDe,
.ingresaTuCorreo,
.tuNftSe {
  font-family: var(--mobile-h33);
}
.elClsicoDe {
  font-weight: 500;
}
.ingresaTuCorreoElectrnico {
  display: block;
}
.correo,
.ingresaTuCorreoContainer {
  position: relative;
  font-size: var(--mobile-p3-size);
  color: var(--black5);
  text-align: left;
}
.ingresaTuCorreoContainer {
  margin: 0;
  align-self: stretch;
}
.correo {
  cursor: pointer;
  font-family: var(--mobile-h33);
}
.campo,
.correoElectrnico {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.campo {
  border: 1px solid var(--gray3);
  font-weight: 500;
  font-family: var(--p);
  font-size: var(--mobile-p3-size);
  background-color: var(--white-bg1);
  border-radius: var(--br-sm);
  flex-direction: row;
  padding: var(--padding-7xs);
}
.correoElectrnico {
  border-radius: var(--br-lg);
  flex-direction: column;
  gap: var(--gap-2xs);
}
.vinculada {
  position: relative;
  font-size: var(--small-size);
  font-family: var(--p);
  color: var(--color-lightseagreen);
  text-align: left;
  display: none;
}
.vinculadoChild {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.vinculado {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.aceptarTrminosY {
  position: relative;
  font-size: var(--font-size-xs);
  font-weight: 600;
  font-family: var(--mobile-h33);
  color: var(--black5);
  text-align: center;
}
.metamask1 {
  position: relative;
  font-size: var(--small-size);
  line-height: 100%;
  font-family: var(--p);
  color: var(--white-bg1);
  text-align: center;
}
.botonenviar {
  align-self: stretch;
  border-radius: var(--br-2xs);
  background-color: var(--black5);
  box-shadow: 0 1px 1px rgba(0, 20, 41, 0.75);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-7xs);
  align-items: center;
  justify-content: center;
}
.botnMobile,
.newsLatter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.botnMobile {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  align-self: stretch;
}
.newsLatter {
  flex: 1;
  border-radius: var(--br-2xs);
  background-color: var(--light-gray-bg1);
  overflow: hidden;
  gap: var(--gap-lg);
}
.checkbox {
  cursor: pointer;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: var(--small-size);
  color: var(--black5);
}
