.metamask {
  position: relative;
  font-size: var(--small-size);
  line-height: 100%;
  font-family: var(--small);
  text-align: center;
}
.botontdc {
  cursor: pointer;
  border: 0;
  padding: 0;
  min-height: 30px;
  background-color: var(--black5);
  align-self: stretch;
  border-radius: var(--br-2xs);
  box-shadow: 0 1px 1px rgba(0, 20, 41, 0.75);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.icon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.white {
  background-color: var(--white-bg1);
  color: var(--black5);
}
.blue {
  background-color: var(--black5);
  color: var(--white-bg1);
}
.disabled {
  background-color: var(--gray5);
  color: var(--white-bg1);
  cursor: default;
}