.logoIcon {
  flex: 1;
  position: relative;
  max-height: 100%;
  width: auto;
  cursor: pointer;
}
.logoWrapper {
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.profileIcon {
  cursor: pointer;
}
.profileIcon,
.walletIcon {
  position: relative;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  overflow: hidden;
}
.profileParent {
  gap: var(--gap-4xl);
}
.navbar,
.navbarInner,
.profileParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.navbar {
  align-self: stretch;
  background-color: var(--black5);
  padding: var(--padding-xl) var(--padding-9xl);
  justify-content: space-between;
}
@media screen and (max-width: 960px) {
  .navbar {
    padding-left: var(--padding-6xl);
    padding-right: var(--padding-6xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 630px) {
  .logoWrapper {
    height: 20px;
  }
  .profileIcon,
  .walletIcon {
    width: 20px;
    height: 20px;
  }
  .profileParent {
    gap: var(--gap-2xs);
  }
  .navbar {
    padding: var(--padding-4xs) var(--padding-2xs);
    box-sizing: border-box;
  }
}
