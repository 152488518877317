.imagenColeccinChild {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-lg);
  background-color: var(--blue-bg3);
  width: 130.77px;
  height: 130.77px;
}
.imagenColeccinItem {
  position: absolute;
  top: 8.65px;
  left: 8.65px;
  width: 113.46px;
  height: 113.46px;
  object-fit: cover;
}
.imagenColeccin {
  position: relative;
  width: 130.77px;
  height: 130.77px;
  flex-shrink: 0;
}
.estebanDido {
  position: relative;
  letter-spacing: 0.98px;
  font-weight: 500;
  text-align: left;
}
.x333c708 {
  letter-spacing: 0.98px;
  opacity: 0.5;
}
.x333c708:hover {
  opacity: 1
}
.x333c708Wrapper {
  position: relative;
  width: auto;
  flex-shrink: 0;
}
.shareNodes {
  position: absolute;
  top: 1.34%;
  left: 25.2%;
  letter-spacing: 0.98px;
}
.iconSharesvg {
  position: relative;
  width: 31px;
  flex-shrink: 0;
  overflow: hidden;
  font-size: var(--font-size-lg);
  font-family: var(--font-font-awesome-6-free);
  opacity: 0.5;
}
.iconSharesvg:hover{
  opacity: 1;
}
.contrato,
.contrato1 {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
}
.contrato1 {
  flex-direction: row;
  font-size: var(--font-size-5xl);
}
.contrato {
  width: 213px;
  flex-direction: column;
  gap: 16px;
}
.contratoWrapper {
  flex: 1;
  position: relative;
  height: auto;  
}
.profile {
  width: 431px;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-2xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xl);
  z-index: 0;
}
.items1 {
  font-weight: 500;
}
.items1Wrapper {
  background-color: var(--color-gray-500);
  width: 100%;
  height: 54px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 15px;
}
.profileHeader{
  position:relative;
  width: 100%;
  max-width: 1200px;
  height: 290px;
  flex-shrink: 0;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
}

.profileHeaderInner{
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
}

.contentCenter{
  display: flex;
  justify-content: center;
}

.profileHeader {
  align-self: stretch;
  display: flex;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  gap: var(--gap-xs);
  background-image: url(../../public/profile-header@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: var(--font-size-5xl);
  color: var(--white-bg1);
  font-family: var(--desk-h31);
}


@media screen and (max-width: 960px) {
  .contrato {
    display: flex;
    flex-direction: column-reverse;
  }
  .contratoWrapper {
    flex: unset;
    align-self: stretch;
  }
  .profile {
    width: auto;
    flex-direction: column;
  }
  /* .profileHeaderInner {
    height: 428px;
  } */
  .profileHeader {
    align-self: stretch;
    flex: 1;
    align-items: center;
    justify-content: center;
    max-width: 1256px;
    min-height: 375px;
  }

  .contrato,
  .contrato1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 420px) {
  .profile {
    width: auto;
  }
  /* .profileHeaderInner {
    height: 375px;
  } */
  /* .profileHeader {
    align-self: stretch;
    width: auto;
    flex: 1;
    align-items: center;
    justify-content: center;
    max-width: 1256px;
    min-height: 375px;
  } */
}
@media screen and (max-width: 965px) {
  .items1Wrapper {
    padding: 0 80px;
  }
}
@media screen and (max-width: 420px) {
  .items1Wrapper {
    padding: 0 24px;
  }
}
