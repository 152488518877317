.icon {
  position: relative;
}
.icon {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  overflow: hidden;
}
.iconos {
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 750px;
  gap: var(--gap-3xl);
}
@media screen and (max-width: 630px) {
  .icon {
    width: 21px;
    height: 21px;
  }
}
