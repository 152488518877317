.imgIcon,
.imgIcon1 {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.imgIcon {
  height: calc(100% - 0.4px);
  width: 100%;
  right: 0;
  bottom: 0.4px;
}
.imgIcon1 {
  height: calc(100% - 131px);
  width: calc(100% - 261.6px);
  right: 261.6px;
  bottom: 131px;
  display: none;
}
.ilustracin,
.imgParent {
  align-self: stretch;
  flex: 1;
}
.imgParent {
  position: relative;
}
.ilustracin {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.elClsico1 {
  font-size: var(--mobile-h33-size);
  color: var(--white-bg1);
}
.elClsico1,
.particula1De {
  align-self: stretch;
  position: relative;
}
.download1Icon {
  width: 14px;
  height: 15px;
  flex-shrink: 0;
}
.div,
.download1Icon {
  position: relative;
}
.download1Parent,
.info {
  display: flex;
  justify-content: flex-start;
}
.download1Parent {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
}
.info {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-5xs);
}
.nft,
.ttulo {
  align-self: stretch;
  display: flex;
}
.ttulo {
  background-color: var(--black5);
  flex-direction: row;
  padding: var(--padding-4xs);
  align-items: center;
  justify-content: flex-start;
}
.nft {
  border-radius: var(--br-sm);
  height: 314px;
  flex-shrink: 0;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
.procederAlPago {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-lg);
  color: var(--black5);
}
.checkbox {
  cursor: pointer;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: var(--small-size);
  color: var(--black5);
}
.termsConditionsLink {
  color: var(--black5);
}
.termsConditionsLink:hover {
  color: var(--black5);
}
.image23Icon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.metamask {
  position: relative;
  font-size: var(--small-size);
  line-height: 100%;
  font-family: var(--small);
  color: var(--black5);
  text-align: center;
}
.botonMetamask {
  cursor: pointer;
  border: 0;
  padding: var(--padding-9xs) var(--padding-7xs);
  background-color: var(--white-bg1);
  align-self: stretch;
  border-radius: var(--br-2xs);
  box-shadow: 0 1px 1px rgba(0, 20, 41, 0.75);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-2xs);
}
.blackscreen,
.botonesMtodoDePago {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.botonesMtodoDePago {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.blackscreen {
  flex: 1;
  width: 300px;
  border-radius: var(--br-2xs);
  background-color: var(--light-gray-bg1);
  overflow: hidden;
  padding: var(--padding-xl);
  justify-content: center;
  gap: var(--gap-lg);
  text-align: left;
  font-size: var(--mobile-p3-size);
  color: var(--persea-nine-white1);
  font-family: var(--mobile-h33);
}
.thanksMessage {
  font-size: var(--mobile-h33-size);
  color: var(--black5);
}
@media screen and (max-width: 630px) {
  .blackscreen {
    width: auto;
  }
}
