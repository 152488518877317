.img1 {
    align-self: stretch;
    display: flex;
}

.img1 {
  position: relative;
  border-radius: var(--br-md1);
  box-sizing: border-box;
  width: 300px;
  height: 140px;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-md);
  align-items: center;
  justify-content: center;
}

.img1Background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: 100% 100%;
}

.logoFotoO {
    position: relative;
    font-family: var(--desk-h31);
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    color: #001429;
}


@media screen and (max-width:1080px){
    .img1{
      width: calc(100% - 5px);
    }
}
  