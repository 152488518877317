.loremIpsumDolor,
.titulo {
  position: relative;
}

.titulo{
  line-height: var(--line-height-desktop);
}

.loremIpsumDolor {
  font-size: var(--desk-p2-size);
  line-height: var(--line-height-desktop);
}
.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-lg);
}
.divColeccin,
.galerry,
.row1 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.row1 {
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-4xl);
}
.divColeccin,
.galerry {
  flex-direction: column;
  align-items: center;
  font-size: var(--desk-p2-size);
  color: var(--persea-nine-white);
}
.divColeccin {
  background-color: var(--blue-dark-bg1);
  overflow: hidden;
  /* width: calc(100% - 0px); */
  /* margin: 0 auto; */
  padding: 26px 0;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-4xl);
  text-align: left;
  font-size: var(--desk-h31-size);
  color: var(--white-bg1);
  font-family: var(--desk-h31);
}

.gridContent{
  display: grid;
  width: 100%;
  gap: 32px;
  grid-template-columns: repeat(3,1fr);
}

.gridContent > , a
{
  text-decoration: none;
}

.anyItems{
  width: 100%;
  padding: 50px 0px;
  text-align: center;
  line-height: var(--line-height-desktop);
}


@media screen and (max-width: 965px) {
  .row1 {
    align-items: center;
    justify-content: center;
  }
  .text {
    align-self: stretch;
    width: auto;
  }
  .row1 {
    flex-direction: column;
  }
  .divColeccin{
    padding: 26px 80px;
  }
  .gridContent{
    grid-template-columns: repeat(2,1fr);
  }
}
@media screen and (max-width: 630px) {
  .galerry,
  .row1 {
    align-self: stretch;
    width: auto;
  }
  .divColeccin{
    padding: 24px;
  }
  .gridContent{
    grid-template-columns: repeat(1,1fr);
  }
}
