
.losBeneficiosSern{
  font-size: var(--title-desktop);
  margin-bottom: 15px;
  line-height: var(--line-height-desktop);
}

.loremIpsumDolor{
  font-size: var(--p-desktop);
  line-height: var(--line-height-desktop);
}
.loremIpsumDolor,p{
  font-size: var(--p-desktop);
  line-height: var(--line-height-desktop);
}


.closeButtonIcon {
  position: relative;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}
.imgIcon,
.imgIcon1 {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: 100%;
}
.imgIcon {
  height: calc(100% - 0.4px);
  width: 100%;
  right: 0;
  bottom: 0.4px;
}
.imgIcon1 {
  height: calc(100% - 0.4px);
  width: 100%;
  right: 261.6px;
  bottom: 131px;
  display: block;
}
.ilustracin,
.imgParent {
  align-self: stretch;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.imgParent {
  position: relative;
}
.ilustracin {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  
}
.elClsico1 {
  font-size: var(--desk-p2-size);
  color: var(--white-bg1);
  line-height: var(--line-height-desktop);
}
.elClsico1,
.particula1De {
  align-self: stretch;
  position: relative;
}

.particula1De{
  line-height: var(--line-height-desktop);
}
.download1Icon {
  width: 14px;
  height: 15px;
  flex-shrink: 0;
  object-fit: cover;
}
.div,
.download1Icon {
  position: relative;
}
.div{
  line-height: var(--line-height-desktop);
}
.download1Parent,
.info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.info {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-5xs);
}
.metamask {
  position: relative;
  line-height: 100%;
}
.boton,
.ttulo {
  flex-direction: row;
  align-items: center;
}
.boton {
  border-radius: var(--br-2xs);
  background-color: var(--white-bg1);
  box-shadow: 0 1px 1px rgba(0, 20, 41, 0.75);
  overflow: hidden;
  display: none;
  padding: var(--padding-7xs);
  justify-content: center;
  text-align: center;
  color: var(--black5);
  font-family: var(--p1);
}
.ttulo {
  align-self: stretch;
  background-color: var(--black5);
  display: flex;
  padding: var(--padding-4xs);
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.nft {
  border-radius: var(--br-md);
  width: calc(50% + 32px);
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  z-index: 1 ;
}
.general {
  position: relative;
  font-weight: 500;
}
.tab,
.tab1 {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-7xs) var(--padding-4xs);
  align-items: center;
  justify-content: center;
  cursor:pointer;
}
.tab {
  background-color: var(--black5);
}

.tabActive {
  background-color: var(--light-gray-bg1);
  color: var(--black5);
}
.info1
{
  align-self: stretch;
  overflow: hidden;
  align-items: flex-start;
}

.tabs ,
.contentOptions {
  background-color: var(--black4);
  align-self: stretch;
  overflow: hidden;
  align-items: flex-start;
}
.tabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.info1 {
  width: calc(50% - 32px);
  height: 100%;
  flex-direction: column;
  text-align: center;
  font-size: var(--desk-p2-size);
  color: var(--white-bg1);
  font-family: var(--p1);
}
.content,
.info1,
.nftView {
  border-radius: var(--br-md);
  display: flex;
  justify-content: flex-start;
  z-index: 1 ;

}
.content {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-4xl);
  z-index: 1;
}
.nftView {
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-lg);
  max-width: 100%;
  max-width: 1300px;
  max-height: 100%;
  text-align: left;
  font-size: var(--p1-size);
  color: var(--persea-nine-white);
  font-family: var(--desk-h31);
  z-index: 1 ;
}

.contentBuy{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;
  gap: 8px;
  width: 100%;

  height: 40px;


  /* White BG */
  z-index:1;
  background: #F1F2F2;
  box-shadow: 0px 1px 1px rgba(0, 20, 41, 0.75);
  border-radius: 2px;
  font-family: var(--desk-h31);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 16px */
  
  text-align: center;
  
  /* Black */
  
  color: #001429;
  cursor: pointer;
}


.contentBack{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;
  gap: 8px;

  width: 100%;
  height: 40px;

  filter: drop-shadow(0px 1px 1px rgba(0, 20, 41, 0.75));
  border-radius: 2px;
  margin-bottom: 17px;
  z-index:1;

  font-family: var(--desk-h31);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 16px */

  text-align: center;

  /* White BG */

  color: #F1F2F2;
  cursor: pointer;
}

.globalPanel{
  /* background-color: var(--black4); */
  width: 100%;
  height: 500px;
}

.panelGeneral {
  overflow: hidden;
  opacity: 0;
  width: 100%;
  height: 0px;
  transition: opacity 0.5s ease;
}

.panelGeneralActive{
  opacity: 1;
  height: 100%;
  width: 100%;
  transition: opacity 1.5s ease;
}

.bodyGeneral{
  width: 100%;
  height: 0px;
}

.bodyGeneralActive{
  height: 100%;
}

.contentBenefits{
  /* padding: var(--padding-md); */
  height: 100%;
}
.globalContentBenefits{
  padding: 24px var(--padding-md);
}
/******** carousel *********/

.textBenefits{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin-bottom: 16px;
  text-align: left;
}

.beneficios {
  align-self: stretch;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lg);
  text-align: center;
  font-size: var(--small-size);
  font-family: var(--desk-h31);
  height: 225px;
}

.beneficios1 {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.beneficios1 {
  overflow-x: auto;
  display: none;
  gap: var(--gap-lg);
  text-align: center;
  font-size: var(--small-size);
  font-family: var(--desk-h31);
}


.beneficio,
.jerseysFirmadosPorTuJugadoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.beneficio {
  border-radius: var(--br-2xs);
  background: linear-gradient(135deg, #002856, #001429);
  width: 152px;
  height: 152px;
  flex-shrink: 0;
  overflow: hidden;
  padding: var(--padding-4xs) var(--padding-md);
  box-sizing: border-box;
}
/******************/

/******** metadata *********/
.coleccionable,
.info {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.coleccionable {
  align-self: stretch;
  padding: var(--padding-md);
  align-items: center;
  gap: var(--gap-lg);
}

.metadatos {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.listaAtributos {
  gap: var(--gap-2xs);
}
.listaAtributos,
.metadatos {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.atributos1 {
  gap: var(--gap-2xs);
}
.atributos1,
.atributos12 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

/*****************/


.botonesNft,
.share {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.share {
  padding: 0 var(--padding-7xs);
  justify-content: flex-start;
  gap: var(--gap-2xs);
}
.botonesNft {
  align-self: stretch;
  border-top: 1px solid var(--gray5);
  padding: var(--padding-4xs) 0 0;
  justify-content: center;
  font-size: var(--p1-size);
  color: var(--color-white);
  margin-bottom: 16px;
  cursor: pointer;
}
.v982D3041Wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.v982D3041Icon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  object-fit: cover;
  cursor: pointer;
}

.verEnOpensea {
  position: relative;
  font-weight: 500;
}


@media screen and (max-width: 1200px) {
  .content {
    flex-direction: column;
    align-items: flex-start;
    z-index: 1;
  }
  .nft,.info1{
    width:100%;
  }
}

@media screen and (max-width: 600px) {
  .ilustracin,
  .imgParent {
    height: 297px;
  }

  .globalPanel{
    height: 450px;
  }
  .textBenefits{
    text-align: left;
  }
}

@media screen and (max-width: 420px) {
  .verEnOpensea {
    font-size: var(--small-size);
  }
}


