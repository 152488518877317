.imgIcon,
.imgIcon1 {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.imgIcon {
  height: calc(100% - 0.4px);
  width: 100%;
  right: 0;
  bottom: 0.4px;
}
.imgIcon1 {
  height: 100%;
  width: 100%;
  right: 261.6px;
  bottom: 131px;
}
.ilustracin,
.imgParent {
  align-self: stretch;
  flex: 1;
}
.imgParent {
  position: absolute;
  top: 0; left: 0;
  border: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
}
.ilustracin {
  position: relative;
  height: 0;
  padding-top: 160.5%;
  width: 100%;
}
.buyButton {
  width: 100%;
  /* min-height: 38px; */
  font-family: var(--desk-h31);
  font-weight: 700 !important;
  font-size: 16px !important;
  cursor: pointer;
  border: 0;
  padding: 0;
  min-height: 38px;
  align-self: stretch;
  border-radius: var(--br-2xs);
  box-shadow: 0 1px 1px rgba(0, 20, 41, 0.75);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--white-bg1);
  color: var(--black5);
}
.elClsico1 {
  font-size: var(--desk-h31-size);
  color: var(--white-bg1);
}
.elClsico1,
.particula1De {
  align-self: stretch;
  position: relative;
}
.download1Icon {
  width: 14px;
  height: 15px;
  flex-shrink: 0;
  object-fit: cover;
}
.div,
.download1Icon {
  position: relative;
}
.div {
  padding-top: 3px;
}
.download1Parent,
.info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.info {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-3xs);
  padding: var(--padding-4xs);
}
.metamask {
  position: relative;
  line-height: 100%;
}
.boton,
.ttulo {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.boton {
  border-radius: var(--br-2xs);
  background-color: var(--white-bg1);
  box-shadow: 0 1px 1px rgba(0, 20, 41, 0.75);
  overflow: hidden;
  padding: var(--padding-7xs);
  justify-content: center;
  cursor: pointer;
  text-align: center;
  color: var(--black5);
  font-family: var(--p1);
}
.ttulo {
  align-self: stretch;
  background-color: var(--black5);
  /* padding: var(--padding-4xs); */
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.nft {
  flex: 1;
  border-radius: var(--br-md);
  overflow: hidden;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  text-align: left;
  font-size: var(--desk-p2-size);
  color: var(--persea-nine-white);
  font-family: var(--desk-h31);
}
@media screen and (max-width: 960px) {
  .ttulo {
    width: auto;
  }
  .nft {
    flex: unset;
    align-self: stretch;
  }
}
