.imgIcon {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.imgWrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.ilustracinCompletaChild {
  position: absolute;
  right: 6px;
  bottom: 5.95px;
  width: 16px;
  height: 16px;
  overflow: auto;
}
.ilustracinCompleta {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: var(--br-2xs);
  overflow: hidden;
}
.nft1 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.elClsico1,
.enTuPosesin {
  align-self: stretch;
  position: relative;
}
.enTuPosesin {
  font-size: var(--p1-size);
  color: var(--persea-nine-white);
}
.elClsico1Parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.metamask {
  position: relative;
  line-height: 100%;
}
.boton,
.ttulo {
  display: flex;
  flex-direction: row;
}
.boton {
  border-radius: var(--br-2xs);
  background-color: var(--white-bg1);
  box-shadow: 0 1px 1px rgba(0, 20, 41, 0.75);
  overflow: hidden;
  padding: var(--padding-7xs);
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--p1-size);
  color: var(--black5);
  font-family: var(--p1);
}
.ttulo {
  align-self: stretch;
  border-radius: 0 0 var(--br-2xs) var(--br-2xs);
  background-color: var(--black5);
  padding: var(--padding-3xs) var(--padding-6xs);
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.nft {
  width: 327px;
  height: 335px;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  cursor: pointer;
}
.introduccin,
.profileMobile {
  align-self: stretch;
  flex-direction: column;
}
.introduccin {
  width: 100%;
  max-width: 1647px;
  margin: 0 auto;
  height: 530px;
  flex-shrink: 0;
  display: none;
  padding: 0 128px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.profileMobile {
  max-width: 1200px;
  margin: 0 auto;
  background-color: var(--blue-dark-bg1);
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 128px;
}
.logoAmeIcon,
.logoChivasIcon,
.poweredByIcon {
  position: relative;
  width: 86px;
  height: 35px;
  flex-shrink: 0;
}
.logoAmeIcon,
.logoChivasIcon {
  width: 32px;
  height: 42px;
  object-fit: cover;
}
.logoAmeIcon {
  width: 35px;
  height: 35px;
}
.logos,
.poweredByParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.logos {
  width: 107px;
  height: 57px;
  flex-shrink: 0;
  justify-content: flex-end;
  gap: var(--gap-4xl);
}
.poweredByParent {
  align-self: stretch;
  flex: 1;
  padding: 0 var(--padding-9xl);
  justify-content: space-between;
}
.newfootermobile,
.pageprofile {
  align-items: flex-start;
  justify-content: flex-start;
}
.newfootermobile {
  align-self: stretch;
  background-color: var(--black5);
  height: 90px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
  flex-direction: row;
}
.pageprofile {
  position: relative;
  background-color: var(--blue-dark-bg1);
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: var(--desk-p2-size);
  color: var(--white-bg1);
  font-family: var(--desk-h31);
}

.bgProfile{
  width: 100%;
  min-height: calc(100vh - 266px);
}


.sections {
  align-self: stretch;
  background-color: var(--black5);
  display: flex;
  justify-content: center;
}



@media screen and (max-width: 960px) {
  .poweredByParent {
    padding-left: var(--padding-md);
    padding-right: var(--padding-md);
    box-sizing: border-box;
  }
  .newfootermobile {
    display: flex;
  }

  .profileMobile{
    padding: 0px 0px;
  }
}
@media screen and (max-width: 420px) {
  .poweredByParent {
    padding-left: var(--padding-md);
    padding-right: var(--padding-md);
    box-sizing: border-box;
  }
}
