.background,
.el7Tiene {
  position: relative;
  height: auto;
  text-align: center;
  font-weight: 500;
}
.el7Tiene {
  color: var(--gray3);
  height: auto;
}
.attributes {
  height: auto;
  border-radius: var(--br-md);
  background-color: var(--black4);
  border: 1px solid var(--white-bg1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-8xs) var(--padding-4xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-7xs);
  font-size: var(--small-size);
  color: var(--white-bg1);
  font-family: var(--p1);
}

.linkToIpfs{
  color: var(--white-bg1);
}
