.hederprofile {
  background-color: var(--color-gray-600);
  width: 100%;
  max-width: 1647px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--font-size-5xl);
  color: var(--white-bg1);
  font-family: var(--desk-h31);
}
@media screen and (max-width: 1200px) {
  .hederprofile {
    align-self: stretch;
    width: auto;
  }
}
@media screen and (max-width: 960px) {
  .hederprofile {
    align-self: stretch;
    width: auto;
  }
}
@media screen and (max-width: 420px) {
  .hederprofile {
    align-self: stretch;
    width: auto;
  }
}
