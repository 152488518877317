.attributes {
  width: 100%;
  align-self: stretch;
  border-radius: var(--br-md);
  background-color: var(--black4);
  border: 1px solid var(--white-bg1);
  box-sizing: border-box;
  height: 132px;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-4xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-lg);
  font-size: var(--small-size);
}


.el10Tiene,
.partcula,
.imgIcon{
  align-self: stretch;
  position: relative;
}

.imgIcon {
  border-radius: var(--br-2xs);
  flex-shrink: 0;
  object-fit: cover;
}

.partcula{
  border-radius: var(--br-2xs);

  flex-shrink: 0;
  object-fit: cover;
}

.partculaParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-7xs);
}

.el10Tiene,
.partcula {
  font-weight: 500;
  text-align: left;
}