.boletosDoblesEn {
  margin-block-start: 0;
  margin-block-end: 0;
}
.tresClsicosNacionales {
  margin: 0;
  font-size: 19px;
}
.boletosDoblesEnContainer {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.estadioAkron {
  align-self: stretch;
  position: relative;
  color: var(--white-bg1);
  display: inline-block;
  height: 12px;
  flex-shrink: 0;
}
.beneficio,
.boletosDoblesEnLosSiguientParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.boletosDoblesEnLosSiguientParent {
  align-self: stretch;
  gap: var(--gap-5xs);
}
.beneficio {
  border-radius: var(--br-2xs);
  background: linear-gradient(135deg, #002856, #001429);
  width: 300px;
  height: 200px;
  flex-shrink: 0;
  overflow: hidden;
  padding: var(--padding-4xs) var(--padding-md);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--small-size);
  color: var(--red-bg3);
  font-family: var(--desk-h31);
}



@media screen and (max-width:1080px){
  .beneficio{
    width: calc(100% - 5px);
  }
}
