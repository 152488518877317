.logoAmeIcon {
  position: relative;
  /* width: 64px;
  height: 64px; */
  width: 128px;
  height: 128px;
  flex-shrink: 0;
  object-fit: cover;
}
.discordIcon,
.telegramIcon {
  position: relative;
  width: 21.95px;
  height: 17.56px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}
.telegramIcon {
  height: 22.66px;
}
.instagramIcon,
.linkedin2Icon,
.redditAlien2Icon,
.twitterIcon {
  position: relative;
  width: 21.95px;
  height: 21.95px;
  flex-shrink: 0;
  overflow: hidden;
}
.instagramIcon,
.linkedin2Icon,
.redditAlien2Icon,
.twitterIcon {
  width: 48px;
  height: 48px;
  font-size: 28px;
}


.linkedin2Icon,
.redditAlien2Icon {
  width: 21.95px;
  display: none;
}
.linkedin2Icon {
  width: 19.21px;
}
.discordParent,
.logoAmeParent,
.logoAmeParent1,
.logoAmeParent2
 {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xl);
}
.logoAmeParent {

  background-color: var(--red-bg3);
  gap: var(--gap-sm);
}
.logoAmeParent1 {
  width: 100%;
  background-color:var(--red-bg3);
  padding: 64px 24px;
  gap: var(--gap-sm);
}
.logoAmeParent2 {
  width: 100%;
  background-color: var(--yellow-bg3);
  padding: 64px 24px;
  gap: var(--gap-sm);
}

.contentBody{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: var(--gap-3xl);
}

.logoAmeIcon1 {
  position: absolute;
  top: 7.06px;
  left: 6.75px;
  width: 128px;
  height: 128px;
  object-fit: cover;
}
.logoAmeWrapper {
  position: relative;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
}
.frameGroup {
  flex: 1;
  background-color: var(--yellow-bg3);
  display: flex;
  flex-direction: row;
  padding: var(--padding-md) 0;
  align-items: center;
  justify-content: center;
  gap: var(--gap-sm);
  height: 256px;
}
.frameParent,
.sectionteams {
  align-self: stretch;
  display: flex;
  height: auto;
}
.frameParent {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 0;
}
.sectionteams {
  
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1200px) {
  .frameGroup,
  .logoAmeParent {
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .frameGroup {
    height: auto;
  }

}
@media screen and (max-width: 960px) {
  .logoAmeParent {
    flex: 1;
    align-self: stretch;
    height: auto;
  }
  .frameGroup,
  .logoAmeParent {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .frameGroup,
  .logoAmeParent1,
  .logoAmeParent2 {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .logoAmeIcon {
    width: 64px;
    height: 64px;
  }

  .instagramIcon,
  .linkedin2Icon,
  .redditAlien2Icon,
  .twitterIcon {
    width: 21.95px;
    height: 21.95px;
    font-size: 28px;
  }


  .logoAmeParent1 ,
  .logoAmeParent2{
    padding: 24px 0px;
    height: 145.95px;
  }  

  .contentBody{
    flex-direction: column;
    height: 145.95px;
  }
}
@media screen and (max-width: 420px) {
  .logoAmeParent {
    align-self: stretch;
    height: auto;
    flex-direction: column;
  }
  .frameGroup,
  .logoAmeParent {
    align-items: center;
    justify-content: center;
  }
  .frameGroup,
  .logoAmeParent1,
  .logoAmeParent2 {
    align-items: center;
    justify-content: center;
  }
}
