.bgIcon {
  align-self: stretch;
  flex: 1;
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  mix-blend-mode: normal;
  width: 100%;
  height: 100%;
  transform: scale(1.3);
  z-index: 0;
  filter: blur(15px);
}
.loremIpsumDolor,
.titulo {
  align-self: stretch;
  position: relative;
  font-size: var(--title-desktop);
}
.loremIpsumDolor {
  font-size: var(--p-desktop);
}
.text {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-2xs);
  z-index: 1;

}

.imgIcon {
  position: relative;
  /* top: calc(50% - 363px);
  left: calc(50% - 752px); */
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  object-fit: center;
}
.vectorIcon {
  position: absolute;
  right: 68.96px;
  bottom: 45.07px;
  width: 53.54px;
  height: 45.4px;
  opacity: 0.75;
}
.imgParent {
  width: 100%;
  height: 100%;
  align-self: stretch;
  flex: 1;
  position: relative;
}
.ilustracin {
  align-self: stretch;
  border-radius: var(--br-md);
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 2;
  max-width: 750px;
  margin: auto;
  /* max-height: 937px; */
}
.adquirirAhora {
  position: relative;
  font-size: var(--small-size);
  line-height: 100%;
  font-family: var(--desk-h31);
  color: var(--white-bg1);
  text-align: center;
}
.boton {
  flex: 1;
  background: linear-gradient(266.87deg, #ff1c39, #7d2248);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-4xs) var(--padding-7xs);
  align-items: center;
  justify-content: center;
}
.adquirirAhora1 {
  position: relative;
  font-size: var(--small-size);
  line-height: 100%;
  font-family: var(--desk-h31);
  color: var(--black5);
  text-align: center;
}
.boton1 {
  flex: 1;
  background: linear-gradient(266.87deg, #f4dc2e, #f97734);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-4xs) var(--padding-7xs);
  align-items: center;
  justify-content: center;
}
.buttons,
.panelesBeneficiosCausa,
.tabs {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.buttons {
  flex-direction: row;
  align-items: flex-start;
}
.panelesBeneficiosCausa,
.tabs {
  flex-direction: column;
}
.tabs {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  align-items: flex-start;
  z-index: 0;
}
.panelesBeneficiosCausa {
  border-radius: var(--br-2xs);
  overflow: hidden;
  align-items: center;
  z-index: 3;
}
.espacioOpcionalPara {
  cursor: pointer;
  position: relative;
}
.soldOut {
  position: relative;
  background: linear-gradient(268.52deg, #f3e82d, #e51d34);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.soldout {
  border-radius: var(--br-xl);
  display: block;
  flex-direction: row;
  padding: var(--padding-4xs) var(--padding-md);
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-6xl);
}
.soldout1,
.text1 {
  display: flex;
  flex-direction: row;
}
.text1 {
  align-self: stretch;
  align-items: center;
  justify-content: center;
  gap: var(--gap-4xl);
  z-index: 4;
}
.soldout1 {
  border-radius: var(--br-sm);
 
  padding: var(--padding-7xs) var(--padding-2xs);
  align-items: flex-start;
  justify-content: center;
  z-index: 5;
  font-size: var(--desk-h31-size);
}
.coleccin {
  align-self: stretch;
  background-color: var(--black5);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  height: auto;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: var(--gap-lg);
  text-align: left;
  font-size: var(--desk-p2-size);
  color: var(--white-bg1);
  font-family: var(--desk-h31);
  padding: 64px 128px;
}

.body{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  z-index: 1 ;
  max-width: 1200px;
  line-height: var(--line-height-desktop);
}

.imgNFt{
  width: 100%;
}

@media screen and (max-width: 960px) {
  .bgIcon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .espacioOpcionalPara {
    flex: 1;
    text-align: center;
  }
  .text1 {
    width: auto;
    flex: 1;
    flex-direction: column;
  }
  .coleccin{
    padding: 24px 24px;
  }
  .body{
    width: 100;
    z-index: 1 ;
    max-width: 900px;
  }
  .ilustracin{
    width: 100%;
    height: auto;
  }
  .vectorIcon {
    position: absolute;
    right: 21px;
    bottom: 16.6px;
  }

  .imgIcon {
    width: 100%;
    height: auto;
  }

  .loremIpsumDolor {
    font-size: var(--p1-size);
  }

  .title{
    font-size: var(--title-desktop);
  }

}
