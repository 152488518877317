.logoVersusIcon {
  flex: 1;
  position: relative;
  max-height: 100%;
  width: auto;
  overflow: hidden;
}
.logoVersusWrapper {
  height: 118.99px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.playerImage {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: scale-down;
}
.playerWrapper,
.frameGroup {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
}
.playerWrapper {
  align-self: stretch;
  width: auto;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameGroup {
  height: 80vh;
  padding: 0 var(--padding-8xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.frameParent,
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.frameParent {
  padding-top: var(--padding-5xl);
  height: 100%;
}
.header {
  height: calc(100vh - 104px);
  overflow: hidden;
}
.frameParent {
  gap: var(--gap-7xl);
}
.header {
  align-self: stretch;
  /* background-image: url(../../public/section-presentation-background.png); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@media screen and (max-width: 630px) {
  .logoVersusWrapper {
    height: 74px;
  }
  /* .frameParent {
    padding-top: var(--padding-7xl);
  } */
  .header {
    box-sizing: border-box;
    height: calc(100vh - 44px);
  }
  /* .frameGroup {
    height: 90vh;
  } */
}
