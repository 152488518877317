.content{
  background: var(--blue-dark-bg1);
  width: 100%;
  min-height: 92vh;
}

.coleccin{
  align-self: stretch;
  background-color: var(--black5);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  height: auto;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: var(--gap-lg);
  text-align: left;
  font-size: var(--desk-p2-size);
  color: var(--white-bg1);
  font-family: var(--desk-h31);
 
}

.desktop{
  display: block;
  position:relative;
  overflow: hidden;
  padding: 64px 128px;
  max-width: 1656px;
  margin: 0 auto;
}

.mobile{
  display: none;
  overflow: hidden;
  position:relative;
}

.bgIcon {
  align-self: stretch;
  flex: 1;
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  mix-blend-mode: normal;
  width: 100%;
  height: 100%;
  transform: scale(1.5);
  z-index: 0;
  filter: blur(15px);
}

.bgIconDesk {
  align-self: stretch;
  flex: 1;
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 0;
  max-width: 110%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  mix-blend-mode: normal;
  width: 100%;
  height: 100%;
  transform: scale(1.199);
  z-index: 0;
}




@media screen and (max-width:1200px){
  
  /* .desktop{
    display: none;
  }
  
  .mobile{
    display: block;
  } */
}

@media screen and (max-width: 900px) {
  .desktop{
    padding: 24px 24px;
  }
}

