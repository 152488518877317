.adquirirAhora {
  position: relative;
  font-size: var(--small-size);
  line-height: 100%;
  font-family: var(--small);
  color: var(--black5);
  text-align: center;
}
.botoninitcancelar {
  cursor: pointer;
  border: 0;
  padding: var(--padding-7xs);
  background-color: transparent;
  align-self: stretch;
  border-radius: var(--br-2xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: none;
}
