.descripcin1 {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.fueElAo {
  margin-block-start: 0;
  margin-block-end: 0;
}
.coleccinConmemorativaDel {
  margin: 0;
}
.fueElAoContainer {
  align-self: stretch;
  position: relative;
  font-size: var(--p1-size);
  font-weight: 500;
}
.descripcin {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lg);
}
.v982D3041Icon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  object-fit: cover;
}
.v982D3041Wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.verEnOpensea {
  position: relative;
  font-weight: 500;
}
.botonesNft,
.share {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.share {
  padding: 0 var(--padding-7xs);
  justify-content: flex-start;
  gap: var(--gap-2xs);
}
.botonesNft {
  align-self: stretch;
  border-top: 1px solid var(--gray5);
  padding: var(--padding-4xs) 0 0;
  justify-content: center;
  font-size: var(--p1-size);
  color: var(--color-white);
}
.coleccionable,
.info {
  
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.coleccionable {
  align-self: stretch;
  background-color: var(--black4);
  padding: var(--padding-4xs) var(--padding-4xs) var(--padding-2xs);
  align-items: center;
  gap: var(--gap-lg);
  text-align: left;
  font-size: var(--desk-h31-size);
}
.info {
  border-radius: var(--br-2xs);
  width: 100%;
  align-items: flex-start;
  text-align: center;
  font-size: var(--desk-p2-size);
  color: var(--white-bg1);
  font-family: var(--p1);
}
@media screen and (max-width: 420px) {
  .descripcin1 {
    font-size: var(--desk-p2-size);
  }
  .verEnOpensea {
    font-size: var(--small-size);
  }
}
