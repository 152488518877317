.sectionfaqs,
.sections {
  align-self: stretch;
  background-color: var(--black5);
  display: flex;
}
.sectionfaqs {
  flex-direction: row;
  padding: var(--padding-md) var(--padding-5xs) 0;
  align-items: center;
  justify-content: center;
}
.sections {
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.logoAmeIcon,
.logoChivasIcon,
.poweredByIcon {
  position: relative;
  width: 86px;
  height: 35px;
  flex-shrink: 0;
}
.logoAmeIcon,
.logoChivasIcon {
  width: 32px;
  height: 42px;
  object-fit: cover;
}
.logoAmeIcon {
  width: 35px;
  height: 35px;
}
.logos {
  width: 107px;
  height: 57px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-4xl);
}
.newfootermobile,
.poweredByParent {
  align-self: stretch;
  flex-direction: row;
}
.poweredByParent {
  flex: 1;
  display: flex;
  padding: 0 var(--padding-9xl);
  align-items: center;
  justify-content: space-between;
}
.newfootermobile {
  background-color: var(--black5);
  height: 90px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
  align-items: flex-start;
  justify-content: flex-start;
}
.vectorIcon {
  position: relative;
  width: 14px;
  height: 14px;
  flex-shrink: 0;
}
.cerrar {
  align-self: stretch;
  border-radius: var(--br-2xs);
  box-shadow: 0 1px 1px rgba(0, 20, 41, 0.75);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 7px var(--padding-7xs) 0 0;
  align-items: center;
  justify-content: flex-end;
}
.ilustracinCompletaChild {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  overflow: auto;
}
.ilustracinCompleta,
.nft1 {
  align-self: stretch;
  flex: 1;
  display: flex;
}
.ilustracinCompleta {
  border-radius: var(--br-2xs);
  overflow: hidden;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  background-image: url(../../public/ilustracin-completa@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.nft1 {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.download1Icon,
.elClsico1 {
  position: relative;
}
.download1Icon {
  width: 14px;
  height: 15px;
  flex-shrink: 0;
  object-fit: cover;
}
.div {
  position: relative;
  font-weight: 500;
}
.download1Parent,
.ttulo {
  justify-content: flex-start;
}
.download1Parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--persea-nine-white);
  font-family: var(--p1);
}
.ttulo {
  border-radius: 0 0 var(--br-2xs) var(--br-2xs);
  background-color: var(--black5);
  flex-direction: column;
  padding: var(--padding-3xs) var(--padding-6xs);
  align-items: flex-start;
  gap: var(--gap-6xs);
}
.frameParent,
.nft,
.nftWrapper,
.ttulo {
  align-self: stretch;
  display: flex;
}
.nft {
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
.frameParent,
.nftWrapper {
  flex-direction: row;
}
.nftWrapper {
  flex: 1;
  padding: 0 5px 0 0;
  align-items: center;
  justify-content: center;
}
.frameParent {
  padding: 0 var(--padding-lg) var(--padding-lg);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-2xl);
}
.home,
.nftView {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nftView {
  border-radius: var(--br-md);
  background-color: var(--blue-dark-bg1);
  width: 933px;
  overflow-y: auto;
  justify-content: center;
  gap: var(--gap-5xs);
}
.home {
  position: relative;
  width: 100%;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--desk-h31-size);
  color: var(--white-bg1);
  font-family: var(--desk-h31);
}
@media screen and (max-width: 960px) {
  .poweredByParent {
    padding-left: var(--padding-md);
    padding-right: var(--padding-md);
    box-sizing: border-box;
  }
  .newfootermobile {
    display: flex;
  }
  .nftWrapper {
    flex: 1;
  }
}
@media screen and (max-width: 420px) {
  .poweredByParent {
    padding-left: var(--padding-md);
    padding-right: var(--padding-md);
    box-sizing: border-box;
  }
  .cerrar {
    display: none;
  }
  .nft1 {
    height: 275px;
  }
  .elClsico1 {
    font-size: var(--desk-p2-size);
  }
  .div {
    font-size: var(--p1-size);
  }
  .nft {
    height: 275px;
  }
  .nftWrapper {
    flex: unset;
    align-self: stretch;
  }
  .frameParent {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .nftView {
    padding: var(--padding-md);
    box-sizing: border-box;
  }
}
