.el10Tiene,
.imgIcon,
.partcula {
  align-self: stretch;
  position: relative;
}
.imgIcon {
  border-radius: var(--br-2xs);
  max-height: 100%;
  width: 82px;
  flex-shrink: 0;
  object-fit: cover;
}
.el10Tiene,
.partcula {
  font-weight: 500;
}
.el10Tiene {
  color: var(--gray3);
}
.partculaParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-7xs);
}
.attributes {
  align-self: stretch;
  border-radius: var(--br-md);
  background-color: var(--black5);
  border: 1px solid var(--white-bg1);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-4xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-lg);
  text-align: left;
  font-size: var(--small-size);
  color: var(--white-bg1);
  font-family: var(--p1);
}
