.bgIcon {
  align-self: stretch;
  flex: 1;
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  mix-blend-mode: normal;
  width: 100%;
  height: 100%;
  transform: scale(1.3);
  z-index: 0;
  filter: blur(15px);
}

.loremIpsumDolor,
.titulo {
  margin: 0;
  align-self: stretch;
  position: relative;
}
.itemsContainer {
  display: grid;
  width: 100%;
  gap: 32px;
  grid-template-columns: repeat(5,1fr);
  padding-right: var(--padding-9xl);
  padding-left: var(--padding-9xl);
  z-index: 2;
}
.itemWrapper {
  width: 15vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}
.carouselItems {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  /* width: auto !important; */
}
.titulo {
  font-weight: 700;
  font-family: inherit;
  font-size: var(--title-desktop);
  line-height: var(--line-height-desktop);
}
.loremIpsumDolor {
  font-size: var(--p-desktop);
  line-height: var(--line-height-desktop);
}
.loremIpsumDolor ,p{
  font-size: var(--p-desktop);
  line-height: var(--line-height-desktop);
}
.content,
.text {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-9xl);
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-lg);
}
.content {
  align-items: center;
  justify-content: flex-start;
  padding-bottom: var(--padding-4xs);
}
.galleryDotIcon {
  position: relative;
  width: 8px;
  height: 8px;
  flex-shrink: 0;
}
.galleryDotIcon1,
.galleryDotIcon3 {
  position: relative;
  width: 6px;
  height: 6px;
  flex-shrink: 0;
  opacity: 0.25;
}
.galleryDotIcon3 {
  display: none;
}
.dots,
.dotsWrapper {
  align-items: center;
  justify-content: center;
}
.dots {
  display: flex;
  flex-direction: row;
  gap: var(--gap-2xs);
}
.dotsWrapper {
  align-self: stretch;
  display: none;
  flex-direction: column;
  padding: var(--padding-4xs) 0;
}
.collectioncontainer,
.store {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.collectioncontainer {
  position: relative;
  align-self: stretch;
  overflow: hidden;
  padding: var(--padding-5xl) 0;
  gap: var(--gap-4xl);
 /*  background-image: url(https://persea.mypinata.cloud/ipfs/QmSS2TpK2fMdvVzzaPbKniHtkbagkYA1Wx83qjnpE2NdwC); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 2;
}
.store {
  width: 100%;
  text-align: left;
  font-size: var(--desk-h31-size);
  color: var(--white-bg1);
  font-family: var(--desk-h31);
}
@media screen and (max-width: 1920px) {
  .itemsContainer {
    grid-template-columns: repeat(3,1fr);
  }
}
@media screen and (max-width: 960px) {
  .content,
  .text {
    padding-left: var(--padding-6xl);
    padding-right: var(--padding-6xl);
    box-sizing: border-box;
  }
  .content {
    padding-bottom: var(--padding-sm);
  }
  .itemWrapper {
    width: 22vw;
    gap: 20px;
  }
  .itemsContainer {
    grid-template-columns: repeat(2,1fr);
  }

  .bgIcon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 630px) {
  .titulo {
    font-size: var(--title-desktop);
  }
  .loremIpsumDolor ,p{
    font-size: var(--p-desktop);
  }
  .content,
  .text {
    box-sizing: border-box;
  }
  .text {
    gap: var(--gap-2xs);
    padding: var(--padding-md);
  }
  .content {
    padding-left: var(--padding-md);
    padding-right: var(--padding-md);
  }
  .dotsWrapper {
    display: flex;
  }
  .collectioncontainer {
    gap: var(--gap-0);
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .itemWrapper {
    width: 38vw;
    gap: 20px;
  }
  .itemsContainer {
    grid-template-columns: repeat(1,1fr);
  }
}
