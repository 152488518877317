.sectionabout {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: calc(100vh - 300px);
  padding: var(--padding-5xl) 128px;
  /* background-image: url(../../public/section-about-background.png); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: left;
  font-size: var(--desk-h31-size);
  color: var(--white-bg1);
  font-family: var(--desk-h31);
}
.title {
  align-self: stretch;
  position: relative;
  font-family: var(--desk-h31);
  font-weight: 700;
  font-size: var(--title-desktop);
  color: #000000;
  padding-bottom: var(--padding-4xs);
}
.description {
  /* gap: var(--gap-2xs); */
  font-family: var(--desk-h31);
  font-size: var(--desk-h32-size);
  color: #000000;
}
@media screen and (max-width: 960px) {
  .sectionabout {
    padding: 50px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 630px) {
  .sectionabout {
    padding: 24px 24px;
    box-sizing: border-box;
  }
}
