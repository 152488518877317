.adquirirAhora {
  position: relative;
  width: 100%;
  font-size: var(--title-desktop);
  line-height: var(--line-height-desktop);
}
.boton,
.boton1 {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-xs);
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: width 1s ease;
}
.boton {
  background: linear-gradient(266.87deg, #ff1c39, #7d2248);
}
.boton1 {
  background: linear-gradient(266.87deg, #f4dc2e, #f97734);
  color: var(--black5);
}

.active{
  width: 300px;
}

.buttons,
.buttonsWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.buttonsWrapper {
  flex-direction: column;
  z-index: 1;
}
.loremIpsumDolor,
.titulo {
  align-self: stretch;
  position: relative;
}
.titulo {
  display: none;
}
.loremIpsumDolor {
  color: var(--black5);
  font-family:  var(--desk-h31);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: var(--line-height-desktop);
}
.panelBeneficios,
.text {
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--black5)
}
.text {
  display: flex;
  gap: var(--gap-md);
}
.panelBeneficios {
  border-radius: 0 0 var(--br-md) var(--br-md);
  background-color: var(--light-gray-bg);
  overflow: hidden;
  display: none;
  padding: var(--padding-lg) var(--padding-md);
  z-index: 0;
  text-align: left;
  font-size: var(--mobile-h3-size);
  color: var(--black);
}
.boton2,
.property1closed {
  overflow: hidden;
  display: flex;
  align-items: center;
}
.property1closed {
  align-self: stretch;

  flex-direction: column;
  justify-content: flex-start;
}
.boton2 {
  background: linear-gradient(266.87deg, #ff1c39, #7d2248);
  width: 207px;
  flex-shrink: 0;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-xs);
  box-sizing: border-box;
  justify-content: center;
}
.losBeneficiosSern {
  align-self: stretch;
  position: relative;
  color: var(--black5);
  font-family:  var(--desk-h31);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: var(--line-height-desktop);
}
.losBeneficiosSern1 {
  align-self: stretch;
  position: relative;
  color: var(--black5);
  font-family:  var(--desk-h31);
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: var(--line-height-desktop);
}
.elClsicoDeLosBeneficios {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-xs);
}
.boletosDoblesEn {
  margin-block-start: 0;
  margin-block-end: 0;
}
.tresClsicosNacionales {
  margin: 0;
  line-height: var(--line-height-desktop);
}
.boletosDoblesEnContainer {
  align-self: stretch;
  position: relative;
  font-weight: 600;
  color: var(--red-bg3);
  font-family: var(--desk-h31);
  font-style: normal;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
}
.estadioAkron {
  align-self: stretch;
  position: relative;
  color: var(--white-bg);
  display: inline-block;
  min-height: 12px;
  flex-shrink: 0;
  font-family: var(--desk-h31);
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: var(--line-height-desktop);
  text-align: center;

}

.newFonts{
  font-family: var(--desk-h31);
  color: var(--white-bg);
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
}

.boletosDoblesEnLosSiguientParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: var(--gap-sm);
}


.beneficio,
.beneficio1 {
  border-radius: var(--br-md);
  background: linear-gradient(135deg, #002856, #001429);
  width: 152px;
  height: 152px;
  flex-shrink: 0;
  overflow: hidden;
  padding: var(--padding-sm) var(--padding-lg);
  box-sizing: border-box;
}
.beneficio {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--red-bg);
}
.beneficio1 {
  color: var(--yellow-bg);
}
.beneficio1,
.beneficio4,
.jerseysFirmadosPorTuJugadoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.jerseysFirmadosPorTuJugadoWrapper {
  align-self: stretch;
}
.beneficio4 {
  border-radius: var(--br-md);
  background: linear-gradient(135deg, #002856, #001429);
  width: 152px;
  height: 152px;
  flex-shrink: 0;
  overflow: hidden;
  padding: var(--padding-sm) var(--padding-lg);
  box-sizing: border-box;
}
.beneficios {
  height: 100%;
  align-self: stretch;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lg);
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--white-bg);
}
.galleryDotIcon,
.galleryDotIcon1 {
  position: relative;
  width: 8px;
  height: 8px;
  flex-shrink: 0;
}
.galleryDotIcon1 {
  width: 6px;
  height: 6px;
  opacity: 0.25;
}
.dots,
.panelBeneficios1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-md);
  height: 100%;
}
.panelBeneficios1 {
  height: 100%;
  align-self: stretch;
  border-radius: 0 0 var(--br-md) var(--br-md);
  background-color: var(--light-gray-bg);
  overflow: hidden;
  flex-direction: column;
  padding: 0px 20px;
  gap: var(--gap-lg);
  text-align: left;
  font-size: var(--mobile-h3-size);
  color: var(--black);
}
.buttonsParent,
.property1beneficios {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

}

.buttonsParent {
  align-items: flex-start;
  z-index: 0;
  height: 0px;
}

.buttonsParentActive{
  height: 100%;
}

.property1beneficios {
  margin-top: 20px;
  border-radius: var(--br-md) var(--br-md) 0 0;
  overflow: hidden;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease;
}
.property1beneficiosActive{
  opacity: 1;
  height: auto;
  transition: opacity 1.5s ease;
}
.boton4,
.buttons2 {
  display: flex;
  flex-direction: row;
}
.boton4 {
  background: linear-gradient(266.87deg, #ff1c39, #7d2248);
  width: 96px;
  flex-shrink: 0;
  overflow: hidden;
  padding: var(--padding-sm) var(--padding-xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.buttons2 {
  align-self: stretch;
  align-items: flex-end;
  justify-content: flex-start;
  z-index: 1;
}
.logoFotoO {
  position: relative;
  font-family: var(--desk-h31);
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  color: #001429;
}
.beneficios1,
.img1 {
  align-self: stretch;
  display: flex;
}
.img1 {
  position: relative;
  border-radius: var(--br-md1);
  box-sizing: border-box;
  width: 300px;
/*   height: 140px; */
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-md);
  align-items: center;
  justify-content: center;
}

.img1Background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: 100% 100%;
}

.beneficios1 {
  overflow-x: auto;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  gap: var(--gap-lg);
  font-size: var(--font-size-xs);
  height: 170px;
}
.caroueles,
.panelBeneficios2 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  
}
.panelBeneficios2 {
  align-self: stretch;
  border-radius: 0 0 var(--br-md) var(--br-md);
  background-color: var(--light-gray-bg);
  padding: 0px 20px;
  align-items: center;
  justify-content: center;
  gap: var(--gap-lg);
  z-index: 0;
  text-align: left;
  font-size: var(--mobile-h3-size);
  color: var(--black);
}
.caroueles {
  position: relative;
  border-radius: 0px 0px 2px 2px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  background: #E6E7E8;
}
.carouelesParent {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--white-bg);
  font-family: var(--mobile-h3);
}


.contentCarousel{
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: height 1s ease;
}

.contentCarouselActive{
  height: 100%;
}

@media screen and (max-width:880px){
  .beneficios1{
    height: 100%;
  }
}

@media screen and (max-width: 960px) {
  .active {
    width: 207px;
  }

  .boton,
  .boton1{
    white-space: nowrap;
    font-size: 10px;
  }

  .contentCarouselActive{
    box-sizing: border-box;
    height: 100%;
  }

  .beneficios1{
    justify-content: flex-start;
  }

  .adquirirAhora{
    white-space: wrap;
    font-size: 14px;
  }
}

@media screen and (max-width: 630px) {
  .boton,
  .boton1{
    padding: var(--padding-sm)  0px;
  }

  .active {
    width: 100%;
  }
}

