.closeButtonIcon,
.imgIcon {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.imgIcon {
  width: 599px;
  height: 480px;
  object-fit: cover;

  /* transform: scale(1.5); */
  user-select: none;
  -webkit-user-select: none; /* Para compatibilidad con navegadores antiguos */
  
}
.ilustracinZoom,
.zoomedIllustration {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.zoomedIllustration {
  align-self: stretch;
  flex: 1;
  border-radius: var(--br-2xs);
  overflow: auto;
  align-items: flex-start;
}
.ilustracinZoom {
  position: relative;
  width: 360px;
  height: 288px;
  overflow: hidden;
  align-items: flex-end;
  gap: var(--gap-2xs);
  max-width: 100%;
  max-height: 100%;
}

/* Anchura del scrollbar */
.zoomedIllustration::-webkit-scrollbar {
  width: 10px;
  /* Anchura del scrollbar */
scrollbar-width: thin;
scrollbar-color: #888 #f1f1f1;
}
.zoomedIllustration::-moz-scrollbar {
  width: 10px;
}
.zoomedIllustration::-ms-scrollbar {
  width: 10px;
}

/* Fondo del scrollbar */
.zoomedIllustration::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.zoomedIllustration::-moz-scrollbar-track {
  background: #f1f1f1;
}
.zoomedIllustration::-ms-scrollbar-track {
  background: #f1f1f1;
}

/* Color de la barra del scrollbar */
.zoomedIllustration::-webkit-scrollbar-thumb {
  background: #888;
}
.zoomedIllustration::-moz-scrollbar-thumb {
  background: #888;
}
.zoomedIllustration::-ms-scrollbar-thumb {
  background: #888;
}


/* Anchura del scrollbar horizontal */
.zoomedIllustration::-webkit-scrollbar {
  height: 10px;
}

.zoomedIllustration::-moz-scrollbar {
  height: 10px;
}

.zoomedIllustration::-ms-scrollbar {
  height: 10px;
}

/* Fondo del scrollbar horizontal */
.zoomedIllustration::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.zoomedIllustration::-moz-scrollbar-track {
  background: #f1f1f1;
}

.zoomedIllustration::-ms-scrollbar-track {
  background: #f1f1f1;
}

/* Color de la barra del scrollbar horizontal */
::-webkit-scrollbar-thumb {
  background: #888;
}

::-moz-scrollbar-thumb {
  background: #888;
}

::-ms-scrollbar-thumb {
  background: #888;
}


