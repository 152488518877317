.sectionartist {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-6xl);
  text-align: left;
  font-size: var(--desk-h31-size);
  color: var(--blue-bg3);
  font-family: var(--desk-h31);
  margin: 0 auto;
  max-width: 1200px;
  padding: var(--padding-5xl) 128px;
}

.body{
  align-self: stretch;
  background-color: var(--light-gray-bg1);
}

/* @media screen and (max-width: 1530px) {
  .sectionartist {
    flex-direction: column;
    padding: var(--padding-5xl) 128px;
  }
} */

@media screen and (max-width: 960px) {
  .sectionartist {
    flex-direction: column;
    padding: var(--padding-5xl) 24px;
  }
}
@media screen and (max-width: 630px) {
  .sectionartist {
    gap: var(--gap-0);
    padding: 0;
    box-sizing: border-box;
  }
}
