.titulo {
  align-self: stretch;
  position: relative;
  font-family: var(--desk-h31);
  font-style: normal;
  font-weight: 700;
  font-size: var(--title-desktop);
  line-height: 20px;
  color: #F1F2F2;
  line-height: var(--line-height-desktop);
}
.loremIpsumDolor {
  width:100%;
  position: relative;
  font-size: var(--p1-size);
  font-family: var(--desk-h31);
  font-style: normal;
  font-weight: 400;
  font-size: var(--p-desktop);
  line-height: var(--line-height-desktop);
  color: #F1F2F2;
}


.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-2xs);
  font-family: var(--desk-h31);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #F1F2F2;
}
.titulo1 {
  position: relative;
  display: none;
}
.frameParent,
.text1,
.textWrapper {
  display: flex;
  flex-direction: column;
}
.text1 {
  width: 327px;
  align-items: center;
  justify-content: center;
  gap: var(--gap-2xs);

}
.frameParent,
.textWrapper {
  align-self: stretch;
  overflow: hidden;
  align-items: flex-start;
  justify-content: flex-start;
}
.textWrapper {
  background-color: var(--blue-dark-bg1);
  height: 1px;
  flex-shrink: 0;
  padding: var(--padding-2xs) var(--padding-xs);
  box-sizing: border-box;
  opacity: 0;
  font-size: var(--desk-p2-size);
}
.frameParent {
  border-radius: var(--br-md);
}
.preguntaNmero1,
.vector {
  position: relative;
  flex-shrink: 0;
}
.preguntaNmero1 {
  display: inline-block;
  width: 279px;
}
.vector {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: var(--white-bg1);
  width: 12px;
  height: 6.5px;
}
.preguntaNmero1Parent {
  align-self: stretch;
  border-radius: var(--br-2xs);
  background-color: var(--black5);
  display: flex;
  flex-direction: row;
  padding: var(--padding-7xs) 0;
  align-items: center;
  justify-content: space-between;
}
.vectorIcon {
  position: relative;
  width: 12px;
  height: 6.5px;
  flex-shrink: 0;
}
.faqdefault,
.instanceParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-2xs);
  font-size: var(--p1-size);
}
.faqdefault {

  margin: 0 auto;
  background-color: var(--black5);
  box-sizing: border-box;
  justify-content: center;
  gap: var(--gap-lg);
  text-align: left;
  font-size: var(--desk-p2-size);
  color: var(--white-bg1);
  font-family: var(--desk-h31);
}

.contentBody{
  width: calc(100% - 256px);
  max-width: 1200px;
  padding: var(--padding-5xl) 0px;
  margin: 0 auto;
}


@media screen and (max-width:960px){
  .contentBody{
    width: calc(100% - 48px);
    padding: var(--padding-5xl) 0px;
  }
}
