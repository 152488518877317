.poweredBy {
  position: relative;
  width: 128px;
  height: 52.75px;
  flex-shrink: 0;
}

.vectorIcon {
  position: absolute;
  height: 102.67%;
  width: 101.1%;
  top: -1.33%;
  right: -0.55%;
  bottom: -1.33%;
  left: -0.55%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.frame {
  position: absolute;
  width: 66.93%;
  top: calc(50% - 10.97px);
  right: 16.54%;
  left: 16.53%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-4xs);
}


.groupIcon,
.groupIcon1 {
  position: relative;
  width: 20.95px;
  height: 21.96px;
  flex-shrink: 0;
  
}
.groupIcon1 {
  width: 58.73px;
  height: 22.01px;
  object-fit: cover;
}

.logoAmeIcon,
.logoChivasIcon {
  width: 52px;
  width: 52px;
}

.chivasBig{
  width: 58px;
  width: 58px;
}

.logos {
  width: 129.36px;
  height: 60px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.newfooterdesktop,
.poweredByParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
}
.poweredByParent {
  flex: 1;
  align-items: center;
  justify-content: space-between;
}
.newfooterdesktop {
  background-color: var(--black5);
  height: 108px;
  flex-shrink: 0;
  overflow: hidden;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 27.63px 128px;
}

.poweredByIcon{
  height: 45px;
}

.perseaIcon{
  height: 40px;
}

@media screen and (max-width: 960px) {
  .poweredBy {
    width: 83px;
    height: 25px;
  }
  .poweredByParent {
    box-sizing: border-box;
  }

  .logoAmeIcon,
  .logoChivasIcon {
    width: 35px;
    height: 35px;
  }

  .chivasBig{
    width: 37px;
    width: 37px;
  }

  .logos {
    width: auto;
  }
  
  .newfooterdesktop {
    padding: 27.64px 24px;
  }
}

@media screen and (max-width: 660px) {
  .poweredBy {
    width: 83px;
    height: 25px;
  }
  .newfooterdesktop {
    padding: 27.64px 24px;
  }

  .poweredByIcon{
    height: 30px;
  }
  .perseaIcon{
    height: 25px;
  }
}


