
.imgIcon {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.imgWrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.ilustracinCompletaChild {
  position: absolute;
  right: 6px;
  bottom: 6.25px;
  width: 16px;
  height: 16px;
  overflow: auto;
  
}
.ilustracinCompleta {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: var(--br-2xs);
  overflow: hidden;
}
.nft1 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.elClsico1,
.enTuPosesin {
  position: relative;
}
.enTuPosesin {
  align-self: stretch;
  font-size: var(--p1-size);
  color: var(--persea-nine-white);
}
.nft,
.ttulo {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.ttulo {
  border-radius: 0 0 var(--br-2xs) var(--br-2xs);
  background-color: var(--black5);
  padding: var(--padding-3xs) var(--padding-6xs);
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.nft {
  height: 340px;
  flex-shrink: 0;
  overflow: hidden;
  justify-content: flex-end;
  z-index: 0;
  text-align: left;
  font-family: var(--desk-h31);
}
.general {
  position: relative;
  font-weight: 500;
}
.tab {
  flex: 1;
  background-color: var(--light-gray-bg1);
  padding: var(--padding-9xs) var(--padding-4xs);
  align-items: center;
  justify-content: center;
  color: var(--black5);
}
.tab,
.tab1,
.tabs {
  overflow: hidden;
  display: flex;
  flex-direction: row;
}
.tab1 {
  flex: 1;
  background-color: var(--black5);
  padding: var(--padding-9xs) var(--padding-4xs);
  align-items: center;
  justify-content: center;
}
.tabs {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
}
.descripcin1 {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.fueElAo {
  margin-block-start: 0;
  margin-block-end: 0;
}
.coleccinConmemorativaDel {
  margin: 0;
}
.fueElAoContainer {
  align-self: stretch;
  position: relative;
  font-size: var(--desk-p2-size);
  font-weight: 500;
}
.descripcin {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lg);
  color: var(--white-bg1);
}
.beneficios1 {
  position: relative;
  font-size: var(--desk-p2-size);
  font-weight: 600;
  display: none;
}
.gradient,
.imgIcon1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.imgIcon1 {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.gradient {
  background: linear-gradient(180deg, rgba(21, 21, 21, 0) 67.19%, #151515);
}
.contenido {
  align-self: stretch;
  position: relative;
  font-size: var(--small-size);
  color: var(--persea-nine-white);
}
.texto {
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: var(--padding-4xs) var(--padding-2xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-end;
}
.beneficio {
  position: relative;
  border-radius: var(--br-xl);
  box-shadow: -1px 2px 4px rgba(8, 26, 67, 0.25);
  width: 146px;
  height: 156px;
  flex-shrink: 0;
  overflow: hidden;
}
.beneficioParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.beneficio4 {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-xl);
  box-shadow: -1px 2px 4px rgba(8, 26, 67, 0.25);
  height: 169px;
  flex-shrink: 0;
  overflow: hidden;
}
.beneficios,
.carousel {
  align-self: stretch;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.beneficios {
  gap: var(--gap-lg);
  font-size: var(--font-size-xl);
}
.background,
.blue,
.el7Tiene {
  position: relative;
  font-weight: 500;
}
.blue,
.el7Tiene {
  color: var(--persea-nine-white);
}
.el7Tiene {
  color: var(--persea-nine-gray-2);
}
.attributes {
  flex: 1;
  border-radius: var(--br-md);
  background-color: var(--color-gray-700);
  border: 1px solid var(--persea-nine-green);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-8xs) var(--padding-4xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.atributos1 {
  width: 303px;
  flex-direction: row;
  gap: var(--gap-2xs);
}
.atributos1,
.atributos21,
.listaAtributos {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.atributos21 {
  width: 147px;
  flex-direction: row;
}
.listaAtributos {
  flex-direction: column;
  gap: var(--gap-2xs);
}
.download1Icon {
  position: relative;
  width: 14px;
  height: 15px;
  flex-shrink: 0;
  object-fit: cover;
}
.metaIcon {
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-7xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-2xs);
}
.meta,
.metadatos {
  align-self: stretch;
  align-items: flex-start;
}
.meta {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: var(--micro-size);
  color: var(--color-white);
}
.metadatos {
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-lg);
  font-size: var(--small-size);
}
.v982D3041Icon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  object-fit: cover;
}
.v982D3041Wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.verEnOpensea {
  position: relative;
  font-weight: 500;
}
.share {
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-7xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-2xs);
  font-size: var(--p1-size);
  color: var(--white-bg1);
}
.frameChild,
.vectorIcon {
  position: relative;
  width: 17px;
  flex-shrink: 0;
}
.frameChild {
  height: 19px;
  display: none;
}
.vectorIcon {
  height: 17px;
}
.vectorParent {
  height: 20px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
}
.vectorIcon1 {
  position: relative;
  width: 16px;
  height: 20px;
  flex-shrink: 0;
}
.vectorWrapper {
  height: 20px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.venderYEnviar {
  display: none;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}
.frameItem {
  position: relative;
  width: 17px;
  height: 19px;
  flex-shrink: 0;
}
.share3 {
  display: none;
  flex-direction: column;
  padding: 0 var(--padding-7xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-2xs);
}
.botonesNft {
  align-self: stretch;
  border-top: 1px solid var(--gray5);
  display: flex;
  flex-direction: row;
  padding: var(--padding-4xs) 0 0;
  align-items: center;
  justify-content: space-between;
  font-size: var(--micro-size);
  color: var(--color-white);
}
.coleccionable,
.info {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.coleccionable {
  background-color: var(--black4);
  padding: var(--padding-4xs) var(--padding-4xs) var(--padding-2xs);
  align-items: center;
  gap: var(--gap-lg);
  text-align: left;
  font-size: var(--desk-h31-size);
  color: var(--persea-nine-green);
}
.info {
  border-radius: var(--br-2xs);
  align-items: flex-start;
  z-index: 1;
}
.adquirirAhora {
  position: relative;
  line-height: 100%;
}
.botoncomprar {
  align-self: stretch;
  border-radius: var(--br-2xs);
  background-color: var(--white-bg1);
  box-shadow: 0 1px 1px rgba(0, 20, 41, 0.75);
  overflow: hidden;
  display: none;
  flex-direction: row;
  padding: var(--padding-7xs);
  align-items: center;
  justify-content: center;
}
.boton,
.botoncomprarParent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.boton {
  border-radius: var(--br-2xs);
  box-shadow: 0 1px 1px rgba(0, 20, 41, 0.75);
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-7xs);
  color: var(--white-bg1);
}
.botoncomprarParent {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-2xs);
  z-index: 2;
  font-size: var(--small-size);
  color: var(--black5);
}
.nftViewChild {
  align-self: stretch;
  position: relative;
  height: 1px;
  flex-shrink: 0;
  z-index: 3;
}
.fakeScrollbarChild,
.fakeScrollbarItem {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  left: calc(50% - 4px);
  border-radius: var(--br-2xl);
  background-color: var(--gray3);
  width: 8px;
}

.fakeScrollbar {
  position: absolute;
  margin: 0 !important;
  top: 15px;
  right: 0;
  width: 24px;
  height: 540px;
  flex-shrink: 0;
  overflow: hidden;
  opacity: 0.5;
  z-index: 4;
}
.nftView {
  border-radius: var(--br-2xs);
  background-color: var(--blue-dark-bg1);
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 64px 128px;
  display: flex;
  flex-direction: column;
 
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lg);
  max-width: 100%;
  max-height: 100%;

  text-align: center;
  font-size: var(--desk-p2-size);
  color: var(--white-bg1);
  font-family: var(--p1);
}


.btnBuy{
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 24px;
  gap: 8px;

  width: 117px;
  height: 31px;

  /* White BG */

  background: #F1F2F2;
  box-shadow: 0px 1px 1px rgba(0, 20, 41, 0.75);
  border-radius: 2px;
  z-index: 1;

  font-family: var(--desk-h31);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  /* Black */

  color: #001429;
  cursor: pointer;
}


.btnComeBack{
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 36px;
  gap: 8px;


  height: 36px;

  filter: drop-shadow(0px 1px 1px rgba(0, 20, 41, 0.75));
  border-radius: 2px;

  font-family: var(--desk-h31);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 12px */

  text-align: center;

  /* White BG */

  color: #F1F2F2;
  cursor: pointer;
}

@media screen and (max-width: 620px) {
.nftView{
  width: 100%;
  height: auto;
  padding: 24px 24px;
}
}

@media screen and (max-width: 420px) {
  .general {
    font-size: var(--p1-size);
  }
  .descripcin1 {
    font-size: var(--desk-p2-size);
  }
  .fueElAoContainer {
    font-size: var(--p1-size);
  }
  .verEnOpensea {
    font-size: var(--small-size);
  }

  
}
