.titulo {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  font-size: var(--title-desktop);
  line-height: var(--line-height-desktop);
}
.loremIpsumDolor {
  display: block;
  line-height: var(--line-height-desktop);
}
.loremIpsumDolorContainer {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--desk-p2-size);
  color: var(--black5);
}
.imgIcon,
.text {
  align-self: stretch;
}
.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-lg);
  width: 50%;
}
.imgIcon {
  border-radius: var(--br-md);
  overflow: hidden;
  height: auto;
  flex-shrink: 0;
  object-fit: cover;
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.imgIconWrapper {
  width: 50%;
}
.conoceMsDe,
.imgIcon,
.twitterIcon {
  position: relative;
}
.twitterIcon {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  overflow: hidden;
}
.conoceMsDeSuTrabajoParent,
.conos {
  flex-direction: row;
  align-items: center;
}
.conos {
  display: flex;
  justify-content: center;
  gap: var(--gap-3xl);
}
.conoceMsDeSuTrabajoParent {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  font-size: var(--desk-p2-size);
  color: var(--blue-darker2);
  line-height: var(--line-height-desktop);
}
.content {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
  text-align: left;
  font-size: var(--desk-h31-size);
  color: var(--blue-bg3);
  font-family: var(--desk-h31);
  max-width: 100%;
}
@media screen and (max-width: 960px) {
  .conoceMsDeSuTrabajoParent {
    display: flex;
  }
  .content {
    flex: unset;
    align-self: stretch;
    flex-direction: column;
  }
  .imgIcon {
    height: auto;
  }
  .imgIconWrapper {
    width: 100%;
  }
  .text {
    width: 100%;
  }
}
@media screen and (max-width: 630px) {
  .titulo {
    font-size: var(--title-desktop);
  }
  .loremIpsumDolorContainer {
    font-size: var(--p1-size);
  }
  .text {
    gap: var(--gap-2xs);
  }
  .conoceMsDe {
    font-size: var(--p1-size);
  }
  .twitterIcon {
    width: 21px;
    height: 21px;
  }
  .content {
    padding: var(--padding-md);
    box-sizing: border-box;
    background-image: url(../../../public/artist-background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
}
