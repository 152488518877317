.descripcin1 {
  align-self: stretch;
  position: relative;
  font-weight: 600;
  font-size: var(--title-desktop);
}
.fueElAo {
  margin-block-start: 0;
  margin-block-end: 0;
}
.coleccinConmemorativaDel {
  margin: 0;
}
.fueElAoContainer {
  align-self: stretch;
  position: relative;
  font-size: var(--p1-size);
  font-weight: 500;
}
.descripcin {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lg);
}
.el10Tiene,
.imgIcon,
.partcula {
  align-self: stretch;
  position: relative;
}
.imgIcon {
  border-radius: var(--br-2xs);
 
  flex-shrink: 0;
  object-fit: cover;
}
.el10Tiene,
.partcula {
  font-weight: 500;
}
.el10Tiene {
  color: var(--gray3);
}
.partculaParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-7xs);
}
.attributes {
  align-self: stretch;
  border-radius: var(--br-md);
  background-color: var(--black5);
  border: 1px solid var(--white-bg1);
  box-sizing: border-box;
  height: 132px;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-4xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-lg);
  font-size: var(--small-size);
  margin: 120px 0px;
}
.v982D3041Icon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  object-fit: cover;
}
.v982D3041Wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.verEnOpensea {
  position: relative;
  font-weight: 500;
}
.share {
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-7xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-2xs);
  font-size: var(--p1-size);
  color: var(--white-bg1);
}
.frameChild,
.vectorIcon {
  position: relative;
  width: 17px;
  flex-shrink: 0;
}
.frameChild {
  height: 19px;
  display: none;
}
.vectorIcon {
  height: 17px;
}
.share1,
.vectorParent {
  display: flex;
  align-items: center;
}
.vectorParent {
  height: 20px;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-xs);
}
.share1 {
  flex-direction: column;
  padding: 0 var(--padding-7xs);
  justify-content: flex-start;
  gap: var(--gap-2xs);
}
.vectorIcon1 {
  position: relative;
  width: 16px;
  height: 20px;
  flex-shrink: 0;
}
.vectorWrapper {
  height: 20px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.venderYEnviar {
  display: none;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}
.frameItem {
  position: relative;
  width: 17px;
  height: 19px;
  flex-shrink: 0;
}
.share3 {
  display: none;
  flex-direction: column;
  padding: 0 var(--padding-7xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-2xs);
}
.botonesNft,
.coleccionable {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.botonesNft {
  border-top: 1px solid var(--gray5);
  flex-direction: row;
  padding: var(--padding-4xs) 0 0;
  font-size: var(--micro-size);
  color: var(--color-white);
}
.coleccionable {
  flex: 1;
  /* background-color: var(--black4); */
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-md);
  text-align: left;
  font-size: var(--desk-h31-size);
  color: var(--white-bg1);
  font-family: var(--p1);
}



@media screen and (max-width: 600px) {
  .attributes {
    margin: 16px 0px;
  }
}