.general {
  position: relative;
  font-weight: 500;
}
.tab {
  flex: 1;
  background-color: var(--light-gray-bg1);
  padding: var(--padding-9xs) var(--padding-4xs);
  align-items: center;
  justify-content: center;
  color: var(--black5);
}
.tab,
.tab1,
.tabs {
  overflow: hidden;
  display: flex;
  flex-direction: row;
}
.tab1 {
  flex: 1;
  background-color: var(--black5);
  padding: var(--padding-9xs) var(--padding-4xs);
  align-items: center;
  justify-content: center;
}
.tabs {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--desk-p2-size);
  color: var(--white-bg1);
  font-family: var(--p1);
}
@media screen and (max-width: 420px) {
  .general {
    font-size: var(--p1-size);
  }
}
